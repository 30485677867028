<template>
    <b-container fluid class="full-container ps-md-0">
        <b-row align-v="stretch" class="vh-100">
            <b-col md="4" lg="6" class="d-none d-md-flex bg-image">
                <div class="d-flex align-items-end py-4 bg-text">
                    <b-container>
                        <b-row>
                            <b-col md="9">
                                <div class="text-left">
                                    <router-link to="/">
                                        <img alt="Tixgo" src="@/assets/logo_white.svg" class="mt-2 mb-2" width="120" />
                                    </router-link>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
            <b-col md="8" lg="6" class="d-flex">
                <div class="login w-100 d-flex align-items-center py-4">
                    <b-container>
                        <b-row>
                            <b-col md="9" lg="8" class="mx-auto">
                                <div class="text-center d-block d-md-none">
                                    <router-link to="/">
                                        <img alt="Tixgo" src="@/assets/logo.svg" class="mb-4" width="120" />
                                    </router-link>
                                </div>
                                <CustomerLoginForm 
                                    :redirect_to="`/` + $route.params.host + `/account`"
                                    :verify_to="`/` + $route.params.host + `/account/login`"
                                    :register_to="`/` + $route.params.host + `/account/register`"
                                />
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import CustomerLoginForm from '@/shared/components/CustomerLoginForm'

    export default ({
        components: {
            CustomerLoginForm,
        },
        metaInfo: {
            title: 'Inloggen',
        },
        data() {
            return {}
        },
        methods: {}
    });
</script>

<style scoped>
    .bg-image{
        background-image: image-set(
            url('~@/assets/login-bg.jpg') 1x,
            url('~@/assets/login-bg-2x.jpg') 2x
        );
        background-size: cover;
        background-position: center;
    }
    .bg-image:after{
        content:"";
        background-color:rgba(152,38,66,0.6);
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index:1;
    }
    .bg-text{
        position:relative;
        z-index:2;
        color:#fff;
    }
    .full-container{
        max-width:100%;
    }
</style>